<template>
  <div class="page-index">
    <Header background-image="images/hero.jpg">
      <h2 class="header__title">Trouvez le spécialiste de votre région</h2>

      <h3 class="header__subtitle">
        Comparez les devis avant de vous décider et économisez jusqu'à 40% !
      </h3>

      <TCard class="header__card">
        <div class="card__form">
          <PostalFormStep :progress-value="0" />
        </div>
      </TCard>

      <span class="header__subtext">
        Comparez jusqu’à 6 spécialistes de votre région
      </span>
    </Header>

    <HomeBlock id="usps">
      <Usps :usps="usps" />
    </HomeBlock>
  </div>
</template>

<script>
import HomeBlock from 'chimera/all/components/HomeBlock'
import AbstractHomePage from 'chimera/all/components/page/AbstractHomePage'
import Header from 'chimera/all/themes/blueflow/components/layout/Header'
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep'

export default {
  components: {
    Header,
    HomeBlock,
    PostalFormStep,
    Usps,
  },

  extends: AbstractHomePage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Comparateurdepanneauxsolaires.be',
      headTitleTemplate: '%s',
      headDescription:
        "Entrez votre code postal et comparez gratuitement 6 devis des meilleures entreprises de votre région. ✓ Le meilleur prix pour votre fournisseur de panneaux solaires ✓ Économisez jusqu'à 40% ✓ 100% gratuit!",
      path: '/',
      homeBlockTitle:
        "C'est si facile de commencer à économiser avec des panneaux solaires.",
      usps: [
        {
          title: 'Répondez à quelques questions',
          text: 'Vous nous dites ce que vous cherchez, afin que nous puissions vous mettre en contact avec le bon spécialiste de votre région.',
          logo: require('chimera/all/themes/blueflow/images/usps/pencil.svg'),
        },
        {
          title: 'Obtenez des devis gratuits',
          text: "Vous recevrez jusqu'à six devis de spécialistes répondant à vos besoins. Cela vous permet d'économiser jusqu'à 40%!",
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          title: 'Choisissez le bon spécialiste',
          text: "Nous avons déjà consulté les spécialistes, il ne vous reste plus qu'à comparer les devis et choisir.",
          logo: require('chimera/all/themes/blueflow/images/usps/user.svg'),
        },
      ],
    }
  },
}
</script>
